import React, {
    ReactNode,
    useCallback,
    useMemo,
    useState,
    useRef,
    useEffect,
} from "react";
import styled from "styled-components";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";

import UnstyledStar from "../../svgs/Star.svg";
import SliderArrowLeft from "../../svgs/SliderArrowLeft.svg";
import SliderArrowRight from "../../svgs/SliderArrowRight.svg";
import Breakpoint from "../../constants/Breakpoint";

interface Testemonial {
    name: string;
    role: string;
    rating: number;
    text: string;
    image: string;
}

interface Props {
    testemonials: Testemonial[];
}

const TestemonialsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    margin: ${Spacing.SPACING_7} 0px;
    max-width: 1400px;
    width: 100%;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(
            to left,
            ${Color.RGBA_BACGROUND_LIGHT_2_A_0},
            ${Color.RGBA_BACGROUND_LIGHT_2_A_1} 80%
        );
        height: 100%;
        width: 500px;

        @media (${Breakpoint.TABLET_AND_DOWN}) {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        pointer-events: none;
        background-image: linear-gradient(
            to right,
            ${Color.RGBA_BACGROUND_LIGHT_2_A_0},
            ${Color.RGBA_BACGROUND_LIGHT_2_A_1} 80%
        );
        height: 100%;
        width: 500px;

        @media (${Breakpoint.TABLET_AND_DOWN}) {
            display: none;
        }
    }

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        width: 100vw;
    }
`;

const TestemonialsSlideContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

const TestemonialsSlider = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: min-content;
    margin: 0px 480px;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        margin: 0px ${Spacing.SPACING_3};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 400px;
    margin: 0px ${Spacing.SPACING_2};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        background-color: ${Color.BACKGROUND_LIGHT_1};
        padding: ${Spacing.SPACING_3};
        border-radius: ${Spacing.SPACING_2};
    }
`;

const Name = styled.h3`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    color: ${Color.TEXT_DARK_1};
`;

const Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    color: ${Color.TEXT_DARK_1};
`;

const Role = styled.h4`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px ${Spacing.SPACING_1} 0px;
    color: ${Color.TEXT_DARK_2};
`;

const Avatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: ${Color.TEXT_DARK_2};
    margin-right: ${Spacing.SPACING_2};
`;

const RatingRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: ${Spacing.SPACING_2};
`;

const InfoColumn = styled.div``;

const Star = styled(UnstyledStar)`
    margin-right: ${Spacing.SPACING_1};
`;

const ArrowRightContainer = styled.div<{ canMove: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;

    opacity: ${({ canMove }: { canMove: boolean }) => (canMove ? `1` : `0.5`)};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: none;
    }
`;

const ArrowLeftContainer = styled.div<{ canMove: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;

    opacity: ${({ canMove }: { canMove: boolean }) => (canMove ? `1` : `0.3`)};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: none;
    }
`;

const generateRatingStars = (rating: number) => {
    const stars: ReactNode[] = [];

    if (!rating) {
        return stars;
    }

    for (let i = 1; i <= rating; i++) {
        stars.push(<Star key={i} />);
    }

    return stars;
};

const Testemonials = ({ testemonials }: Props) => {
    const [steps, setSteps] = useState<number>(testemonials.length > 1 ? 1 : 0);
    const sliderRef = useRef<HTMLDivElement>(null);
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        sliderRef.current?.scrollTo({
            left: steps * 440,
            behavior: mountedRef.current ? "smooth" : "auto",
        });

        mountedRef.current = true;
    }, [steps]);

    const canMoveLeft = useMemo(() => {
        return steps > 0;
    }, [steps]);
    const canMoveRight = useMemo(() => {
        return steps < testemonials.length - 1;
    }, [steps, testemonials.length]);

    const moveLeft = useCallback(() => {
        if (canMoveLeft) {
            setSteps((steps) => steps - 1);
        }
    }, [canMoveLeft]);

    const moveRight = useCallback(() => {
        if (canMoveRight) {
            setSteps((steps) => steps + 1);
        }
    }, [canMoveRight]);

    return (
        <TestemonialsRow>
            <ArrowLeftContainer onClick={moveLeft} canMove={canMoveLeft}>
                <SliderArrowLeft />
            </ArrowLeftContainer>
            <ArrowRightContainer onClick={moveRight} canMove={canMoveRight}>
                <SliderArrowRight />
            </ArrowRightContainer>
            <TestemonialsSlideContainer ref={sliderRef}>
                <TestemonialsSlider>
                    {testemonials.map(
                        ({ name, rating, role, text, image }, index) => {
                            return (
                                <Container key={index}>
                                    <HeaderRow>
                                        <Avatar src={image} />
                                        <InfoColumn>
                                            <Name>{name}</Name>
                                            <Role>{role}</Role>
                                            <RatingRow>
                                                {generateRatingStars(rating)}
                                            </RatingRow>
                                        </InfoColumn>
                                    </HeaderRow>
                                    <Text>{text}</Text>
                                </Container>
                            );
                        }
                    )}
                </TestemonialsSlider>
            </TestemonialsSlideContainer>
        </TestemonialsRow>
    );
};

export default Testemonials;
