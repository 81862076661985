import styled from "styled-components";
import Breakpoint, { BreakpointType } from "../../constants/Breakpoint";
import Spacing from "../../constants/Spacing";

interface Props {
    hideOn?: BreakpointType;
}

const ButtonRow = styled.div<Props>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: ${Spacing.SPACING_7};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;
    }

    ${({ hideOn }: Props) => {
        if (!hideOn) {
            return ``;
        }

        return `
            @media (${hideOn}) {
                display: none;
            }
        `;
    }}
`;

export default ButtonRow;
