import React from "react";
import RedStrikeThrough from "../components/Home/RedStrikeThrough";

import Developer from "../svgs/Developer.svg";
import Productive from "../svgs/Productive.svg";
import Interview from "../svgs/Interview.svg";
import Season from "../svgs/Season.svg";
import Lessons from "../svgs/Lessons.svg";
import Features from "../svgs/Features.svg";
import Time from "../svgs/Time.svg";
import michael from "../images/Michael.jpeg";
import paul from "../images/Paul.jpeg";
import kate from "../images/Kate.jpeg";

export const infoGraphics = [
    {
        title: "An essential skill for the Cloud",
        text: `
            In the time of Cloud, Docker, and 
            Kubernetes, you are required to 
            use the Linux command line daily 
        `,
        graphic: <Developer height="160" width="253" />,
    },
    {
        title: "Grow your flexibility",
        text: `
            Tap into hidden functions of your system
            with shortcuts, time-saving commands, and tools
        `,
        graphic: <Productive height="160" width="186" />,
    },
    {
        title: "Land high-paying opportunities",
        text: `
           Gain skills that are a sign of a good developer
           in any employer’s mind
        `,
        graphic: <Interview height="160" width="211" />,
    },
    {
        title: "Save time, work on more projects",
        text: `
            Optimize your process and workflow.
            Get more done with less hardware resources
        `,
        graphic: <Season height="160" width="264" />,
    },
];

export const stories = [
    {
        title: (
            <span>
                Learn By <RedStrikeThrough>Watching</RedStrikeThrough> Doing
            </span>
        ),
        bulletsDescription: "The best way to learn is to do it:",
        bullets: [
            "Interactive lessons and challenges",
            "Online terminal to practice tasks and commands",
            "Tips if you get stuck",
            "The platform tracks your progress",
        ],
        graphic: <Features />,
    },
    {
        title: "Everything You Need Is Here",
        bulletsDescription: "No more Googling for the commands:",
        bullets: [
            "Real-world examples",
            "Developer and DevOps tracks",
            "Cheat sheets, diagrams, extra materials, and much more",
            "Walkthroughs of many of the most popular commands",
        ],
        graphic: <Lessons />,
    },
    {
        title: "Get Up To Speed Fast",
        bulletsDescription: "Make the best use of your time:",
        bullets: [
            "15 minutes a day for 30 days in the Developer program",
            "Self-paced, take lessons any time",
            "Bite-sized lessons",
            "Download cheat sheets as reminders",
        ],
        graphic: <Time />,
    },
];

export const steps = [
    {
        paragraphs: [
            `
                Content is broken down into modules. Each module consists of a set of lessons.
            `,
            `
                Besides lessons, there are walkthroughs and cheat sheets that will 
                help you build your knowledge and skills.
            `,
        ],
    },
    {
        paragraphs: [
            `
                For each lesson, you get an interactive terminal running on our server.
            `,
            `
                You read the materials while doing the exercise in the Terminal
                step by step. Platform monitors and guides you.
            `,
        ],
    },
    {
        paragraphs: [
            `
                Once you have completed a step, you are moved to
                the next step in a lesson.
            `,
            `
                You can always go back to previous steps when needed
                or access extra materials for in-depth understanding.
            `,
        ],
    },
];

export const testemonials = [
    {
        name: "Michael Hawks",
        role: "Software Developer",
        rating: 5,
        text: `
            This platform is a rare find. Most of the learning platforms
            are in boring text form, which can be difficult to comprehend
            at times. With learncmd.io, I was able to get an interactive
            terminal right away and start practicing the commands. They
            have tutorials that walk you through each command with
            step-by-step instructions. In my opinion, it's an absolute must
            for anyone who wants to learn Command Line!
        `,
        image: michael,
    },
    {
        name: "Paul Felton",
        role: "Oil industry engineer",
        rating: 5,
        text: `
            I am an engineer in the oil industry, and I needed to learn
            Linux Terminal for my job. When I found out about learncmd.io beta
            testing program, it seemed like an opportunity too good to
            pass up. The site has a lot of information about how the
            terminal works, but it really shines with interactive tutorials
            that help me quickly learn the commands I need to know.
        `,
        image: paul,
    },
    {
        name: "Kate Hooper",
        role: "Software Developer",
        rating: 5,
        text: `
            I'm a total beginner when it comes to command
            line and I was looking for a good place to start. learncmd.io
            is just what I needed! The tutorials are very well-made, and
            interactive which makes learning the basics of Linux so much
            easier, especially if you have minimal knowledge about operating
            systems. Highly recommended for all beginners!
        `,
        image: kate,
    },
];

export const plans = [
    {
        title: "Starter",
        bulletsDescription: "Try out LearnCMD for free:",
        bullets: [
            "2 Developer modules",
            "1 DevOps module",
            "2 Command walkthroughs",
        ],
        priceDisclaimer: "No credit card required",
    },
    {
        title: "Developer",
        bulletsDescription:
            "Learn everything developer needs to know in 15 minutes a day:",
        bullets: [
            "8 Developer modules",
            "6 Command walkthroughs",
            "Downloadable education materials",
            "Downloadable command cheat sheets",
            "Access to Slack support group",
        ],
        price: 89,
        priceDisclaimer: "7 days money back guarantee",
    },
    {
        title: "DevOps",
        bulletsDescription: "Get full access to all modules and materials:",
        bullets: [
            "8 Developer modules",
            "10 DevOps modules",
            "6 Command walkthroughs",
            "Downloadable education materials",
            "Downloadable command cheat sheets",
            "Access to Slack support group",
        ],
        price: 149,
        priceDisclaimer: "7 days money back guarantee",
    },
];
