import React, { ReactNode } from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";

export const StoryType = {
    TEXT_FIRST: 1,
    GRAPHIC_FIRST: 2,
} as const;

type StoryTypeType = typeof StoryType[keyof typeof StoryType];

interface Props {
    storyType: StoryTypeType;
    title: string | ReactNode;
    bulletsDescription: string;
    bullets: string[];
    graphic: ReactNode;
}

const Container = styled.div<{ storyType: StoryTypeType }>`
    width: 100%;
    margin: ${Spacing.SPACING_7} 0;
    display: flex;
    ${({ storyType }: { storyType: StoryTypeType }) => {
        if (storyType === StoryType.GRAPHIC_FIRST) {
            return `flex-direction: row;`;
        }

        return `flex-direction: row-reverse;`;
    }}
    align-items: flex-start;
    justify-content: center;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;

        svg {
            max-width: 100%;
        }
    }
`;

const BulletsColumn = styled.div<{ storyType: StoryTypeType }>`
    max-width: 530px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;

    ${({ storyType }: { storyType: StoryTypeType }) => {
        if (storyType === StoryType.GRAPHIC_FIRST) {
            return `margin-left: ${Spacing.SPACING_5};`;
        }

        return `margin-right: ${Spacing.SPACING_5};`;
    }}

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: ${Spacing.SPACING_3};
    }
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const BulletsDescription = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const Bullets = styled.ul`
    margin: 0;
`;

const Bullet = styled.li`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const Story = (props: Props) => {
    const { storyType, title, bulletsDescription, bullets, graphic } = props;

    return (
        <Container storyType={storyType}>
            {graphic}
            <BulletsColumn storyType={storyType}>
                <Title>{title}</Title>
                <BulletsDescription>{bulletsDescription}</BulletsDescription>
                <Bullets>
                    {bullets.map((bullet, index) => (
                        <Bullet key={index}>{bullet}</Bullet>
                    ))}
                </Bullets>
            </BulletsColumn>
        </Container>
    );
};

export default Story;
