import React, {
    useState,
    createContext,
    ReactNode,
    ReactElement,
    useContext,
    useCallback,
    useMemo,
} from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing, { SpacingType } from "../../constants/Spacing";

interface TabContextType {
    setActive: (active?: ReactNode) => void;
    active: ReactNode;
}

interface TabProps {
    children: ReactNode;
    label: string;
}

interface TabsProps {
    children: ReactElement<TabProps> | ReactElement<TabProps>[];
    margin?: SpacingType;
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
    marginLeft?: SpacingType;
    marginRight?: SpacingType;
}

interface SelectorProps {
    active: boolean;
}

interface ContainerProps {
    margin?: SpacingType;
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
    marginLeft?: SpacingType;
    marginRight?: SpacingType;
}

const TabContext = createContext<TabContextType>({
    setActive: () => {},
    active: null,
});

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1160px;
    margin: ${({ margin }: ContainerProps) => margin || 0};
    margin-top: ${({ marginTop }: ContainerProps) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: ContainerProps) => marginBottom || 0};
    margin-left: ${({ marginLeft }: ContainerProps) => marginLeft || 0};
    margin-right: ${({ marginRight }: ContainerProps) => marginRight || 0};
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: ${Spacing.SPACING_3};
`;

const Selector = styled.button<SelectorProps>`
    border: none;
    width: 240px;
    height: 72px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;

    ${({ active }: SelectorProps) => {
        if (active) {
            return `
                background-color: ${Color.RGBA_BRAND_2};
                border-radius: ${Spacing.SPACING_2};
                color: ${Color.BRAND_1};
            `;
        }

        return `
            background-color: transparent;
            color: ${Color.TEXT_DARK_2};
        `;
    }}
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: ${Color.BACKGROUND_LIGHT_2};
    padding: ${Spacing.SPACING_4};
    border-radius: ${Spacing.SPACING_2};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        padding: ${Spacing.SPACING_3};
    }
`;

export const Tab = ({ label, children }: TabProps) => {
    const { setActive, active } = useContext(TabContext);

    const onClick = useCallback(() => {
        setActive(children);
    }, [setActive, children]);

    const isActive = active === children;

    return (
        <Selector onClick={onClick} active={isActive}>
            {label}
        </Selector>
    );
};

const Tabs = ({ children, ...rest }: TabsProps) => {
    const initial = useMemo(() => {
        if (!children) {
            return null;
        }

        if (!Array.isArray(children)) {
            const { props } = children;
            const { children: initial } = props;

            return initial;
        }

        const [firstChild] = children;

        if (!firstChild) {
            return null;
        }

        const { props } = firstChild;
        const { children: initial } = props;

        return initial;
    }, [children]);

    const [active, setActive] = useState<ReactNode>(initial);

    return (
        <TabContext.Provider value={{ setActive, active }}>
            <Container {...rest}>
                <Header>{children}</Header>
                <Body>{active}</Body>
            </Container>
        </TabContext.Provider>
    );
};

export default Tabs;
