import styled from "styled-components";
import Spacing from "../../constants/Spacing";

const SectionTitle = styled.h3`
    margin: ${Spacing.SPACING_7} 0px 0px 0px;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    text-align: center;
`;

export default SectionTitle;
