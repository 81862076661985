import React from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";
import Button, { ButtonSize, ButtonType } from "../Common/Button";

interface Props {
    title: string;
    bulletsDescription: string;
    bullets: string[];
    price?: number;
    priceDisclaimer: string;
    action: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 500px;
    width: 100%;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;
        height: fit-content;
    }
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
`;

const CommerceColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        align-items: center;
        width: 100%;
        margin-top: ${Spacing.SPACING_3};
    }
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const BulletsDescription = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_3};
`;

const Bullets = styled.ul`
    margin: 0;
`;

const Bullet = styled.li`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.TEXT_DARK_1};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const PriceSuffix = styled.span`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.TEXT_DARK_1};
`;
const Price = styled.p`
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    color: ${Color.TEXT_DARK_1};
    margin: 0px;
`;

const PriceDisclaimer = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.TEXT_DARK_2};
    margin: 0px;
    margin-bottom: auto;
    @media (${Breakpoint.TABLET_AND_DOWN}) {
        margin-bottom: ${Spacing.SPACING_3};
    }
`;

const Plan = (props: Props) => {
    const {
        title,
        bulletsDescription,
        bullets,
        price,
        priceDisclaimer,
        action,
    } = props;

    return (
        <Container>
            <InfoColumn>
                <Title>{title}</Title>
                <BulletsDescription>{bulletsDescription}</BulletsDescription>
                <Bullets>
                    {bullets.map((bullet, index) => (
                        <Bullet key={index}>{bullet}</Bullet>
                    ))}
                </Bullets>
            </InfoColumn>
            <CommerceColumn>
                {price ? (
                    <Price>
                        {price}$
                    </Price>
                ) : (
                    <Price>FREE</Price>
                )}
                <PriceDisclaimer>{priceDisclaimer}</PriceDisclaimer>
                <Button
                    buttonType={ButtonType.PRIMARY}
                    buttonSize={ButtonSize.LARGE}
                    onClick={action}
                >
                    Get Started
                </Button>
            </CommerceColumn>
        </Container>
    );
};

export default Plan;
