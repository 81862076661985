import styled from "styled-components";
import Color from "../../constants/Color";
import { SpacingType } from "../../constants/Spacing";

interface Props {
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
}

const Subtitle = styled.h5<Props>`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: ${Color.TEXT_DARK_1};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};
`;

export default Subtitle;
