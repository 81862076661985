import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import { SpacingType } from "../../constants/Spacing";

interface Props {
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
}

const Title = styled.h1<Props>`
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    text-align: center;
    color: ${Color.TEXT_DARK_1};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        font-size: 32px;
    }
`;

export default Title;
